
import store from '@/store';
import { computed, defineComponent, onMounted, ref } from 'vue';
import './FlightsUpload.scss';

import globalToast from '@/composables/common/globalToast';
import flightLineHandler from '@/composables/flights/flightLineHandler';
import flightLineUploader from '@/composables/flights/flightLineUploader';

export default defineComponent({
    setup() {
        const { showGlobalToast } = globalToast();
        const { loadFlightLine, zoomToFlight, clearFlightLineKML } = flightLineHandler();
        const { tryToUploadFlightLine } = flightLineUploader();

        const username = computed(() => store.getters.g_userdetails.username);
        const g_districtsList = computed(() => store.getters.g_districtsList);
        const droneNumbersList = computed(() => store.getters.g_droneNumbers);

        const showFlightSummaryBox = computed(() => store.getters.flu_showFlightSummaryBox);

        const computedrefs = { 
            droneNumbersList, g_districtsList, showFlightSummaryBox
        };

        const flightlinekmlValid = computed(() => store.getters.flu_flightlinekml.valid);
        const flightlineAttributes = computed(() => store.getters.flu_flightlinekml.attributes);
        const flightAttributesValid = ref(false);
        const fileEl = ref();

        onMounted(() => {
            fileEl.value.addEventListener('change', loadFlightLine);
        });
        
        const firstPage = ref(true);
        const currentdronenumber = ref('');
        const flightnumber      = ref('');

        const flightid = computed(() => {
            if(currentdronenumber.value != '' && flightnumber.value != ''){
                return currentdronenumber.value + '_' + flightnumber.value;
            } else return '';
        });

        const flightcount       = ref('');
        const flightcategory    = ref('');
        
        const flightdate        = ref('');
        const takeofftime       = ref('');
        const landingtime       = ref('');

        const duration = computed(() => {
            if(takeofftime.value != '' && landingtime.value != ''){
                let hours: any   = <any>landingtime.value.split(':')[0] - <any>takeofftime.value.split(':')[0];
                let minutes: any = <any>landingtime.value.split(':')[1] - <any>takeofftime.value.split(':')[1];
                minutes = minutes.toString().length < 2 ? '0' + minutes : minutes;
                if(minutes < 0){ 
                    hours--;
                    minutes = 60 + minutes;
                }
                hours = hours.toString().length < 2 ? '0' + hours : hours;
                if(hours >= 0 && minutes >= 0){
                    return hours + ':' + minutes;
                } else {
                    return '';
                }
            } else {
                return '';
            }
        });

        const trainingflight    = ref('');
        const freshrefly        = ref('');
        const areacovered       = ref('');
        const flyingheight      = ref('');
        const overlap           = ref('');
        const temperature       = ref('');
        const windspeed         = ref('');
        const pilotname         = ref('');
        const fieldassistant    = ref('');
        const campingarea       = ref('');
        const district          = ref('');
        const taluk             = ref('');
        const grampanchayat     = ref('');
        const villages          = ref('');
        const hamlets           = ref('');
        const lgdcodes          = ref('');
        const villagescount     = ref('');
        const hamletscount      = ref('');
        const softwareversion   = ref('');
        const basegpsid         = ref('');
        const rawimages         = ref('');
        const geotagged         = ref('');
        const avggsd            = ref('');
        const batteryno         = ref('');
        const flylogno          = ref('');
        const ibasefilename     = ref('');
        const totalfiles        = ref('');
        const foldersize        = ref('');
        const remarks           = ref('');
        const attributesStatus = ref('');

        const variableref0 = { flightlinekmlValid, flightlineAttributes, flightAttributesValid, fileEl };
        const variablerefs1 = { ...variableref0, firstPage, currentdronenumber, flightnumber, flightid, flightcount, flightcategory, flightdate };
        const variablerefs2 = { ...variablerefs1, takeofftime, landingtime, duration, trainingflight, freshrefly, areacovered, flyingheight }
        const variablerefs3 = { ...variablerefs2, overlap, temperature, windspeed, pilotname, fieldassistant, campingarea, district, taluk }
        const variablerefs4 = { ...variablerefs3, grampanchayat, villages, hamlets, lgdcodes, villagescount, hamletscount, softwareversion }
        const variablerefs5 = { ...variablerefs4, basegpsid, rawimages, geotagged, avggsd, batteryno, flylogno, ibasefilename }
        const variablerefs = { ...variablerefs5, totalfiles, foldersize, remarks, attributesStatus };

        const isFailedFlight = ref(false);

        const checkNUploadFlight = () => {
            let attributesInfo = {
                'dronenumber': currentdronenumber.value,    'uniqueflightnumber': flightnumber.value,   'flightid': flightid.value,
                'flightcount': flightcount.value,           'flightcategory': flightcategory.value,     'flightdate': flightdate.value,
                'takeofftime': takeofftime.value,           'landingtime': landingtime.value,           'duration': duration.value,
                'trainingflight': trainingflight.value,     'freshrefly': freshrefly.value,             'area': areacovered.value,
                'uavheight': flyingheight.value,            'overlap': overlap.value,                   'temperature': temperature.value,
                'windspeed': windspeed.value,               'pilotname': pilotname.value,               'fieldassistant': fieldassistant.value,
                'campingarea': campingarea.value,           'district': district.value,                 'taluk': taluk.value,
                'grampanchayat': grampanchayat.value,       'villages': villages.value,                 'hamlets': hamlets.value,
                'lgdcodes': lgdcodes.value,                 'villagescount': villagescount.value,       'hamletscount': hamletscount.value,
                'softwareversion': softwareversion.value,   'basegpsid': basegpsid.value,               'ibasefilename': ibasefilename.value,
                'rawimagescount': rawimages.value,          'geotagged': geotagged.value,               'avggsd': avggsd.value,
                'batteryno': batteryno.value,               'flylogno': flylogno.value,                 'totalfiles': totalfiles.value,
                'foldersizegb': foldersize.value,           'remarks': remarks.value,                   'username': username.value
            };

            let condPrime = attributesInfo['flightcategory'] != '';

            if(condPrime){
                let condSuccess = attributesInfo['flightcategory'] == 'SVAMITVA' || attributesInfo['flightcategory'] == 'LSMK_SVAMITVA' || attributesInfo['flightcategory'] == 'LSMK';
                if(condSuccess){
                    isFailedFlight.value = false;
                    const isEmpty = Object.values(attributesInfo).some(x => x === null || x === '');
                    if(isEmpty){
                        errorInAttributes();
                    } else {
                        registerAttributesForUpload(attributesInfo);
                    }
                } else {
                    isFailedFlight.value = true;
                    let cond1 = attributesInfo['dronenumber'] != '';
                    let cond2 = attributesInfo['uniqueflightnumber'] != undefined && attributesInfo['uniqueflightnumber'] != '';
                    let cond3 = attributesInfo['flightid'] != '';
                    let cond4 = attributesInfo['flightcount'] != '';
                    let cond5 = attributesInfo['flightdate'] != undefined && attributesInfo['flightdate'] != '';
                    let cond6 = attributesInfo['district'] != '';
                    let condA = cond1 && cond2 && cond3 && cond4 && cond5 && cond6;
                    if(condA){
                        registerAttributesForUpload(attributesInfo);
                    } else {
                        errorInAttributes();
                    }
                }
            } else {
                errorInAttributes();
            }

            setTimeout(() => {
                attributesStatus.value = '';
            }, 2000);
        }

        const registerAttributesForUpload = (attributesInfo: any) => {
            // console.log(attributesInfo);
            let flightlineKML = store.getters.flu_flightlinekml;
            // console.log(flightlineKML);

            flightlineKML.attributes = attributesInfo;
            store.dispatch('flu_flightlinekml', flightlineKML);

            flightAttributesValid.value = true;
            attributesStatus.value = 'Successfully Updated Attributes...';

            setTimeout(() => {
                store.dispatch('flu_showFlightSummaryBox', true);
            }, 500);
        }

        const errorInAttributes = () => {
            let flightlineKML = store.getters.flu_flightlinekml;
            flightlineKML.attributes = {};

            store.dispatch('flu_flightlinekml', flightlineKML);
            flightAttributesValid.value = false;
            attributesStatus.value = 'Error in Attributes...';
        }

        const startUploading = () => {
            
            if(flightAttributesValid.value){
                // console.log('Valid Attributes to Upload...');
                if(!isFailedFlight.value && !flightlinekmlValid.value){
                    showGlobalToast('Select Valid Flight Line KML');
                    return 0;
                }
                    
                tryToUploadFlightLine(flightlineAttributes.value, isFailedFlight.value);
            } else {
                showGlobalToast('Error In Attributes');
            }
        }

        const closeFlightSummaryBox = () => {
            store.dispatch('flu_showFlightSummaryBox', false);
        }

        const editAttributes = () => {
            store.dispatch('flu_showFlightSummaryBox', false);
        }

        const clearAllCells = () => {
            currentdronenumber.value    = '';
            flightnumber.value          = '';
            flightcount.value           = '';
            flightcategory.value        = '';
            
            flightdate.value        = '';
            takeofftime.value       = '';
            landingtime.value       = '';
            trainingflight.value    = '';
            freshrefly.value        = '';
            areacovered.value       = '';
            flyingheight.value      = '';
            overlap.value           = '';
            temperature.value       = '';
            windspeed.value         = '';
            pilotname.value         = '';
            fieldassistant.value    = '';
            campingarea.value       = '';
            district.value          = '';
            taluk.value             = '';
            grampanchayat.value     = '';
            villages.value          = '';
            hamlets.value           = '';
            lgdcodes.value          = '';
            villagescount.value     = '';
            hamletscount.value      = '';
            softwareversion.value   = '';
            basegpsid.value         = '';
            rawimages.value         = '';
            geotagged.value         = '';
            avggsd.value            = '';
            batteryno.value         = '';
            flylogno.value          = '';
            ibasefilename.value     = '';
            totalfiles.value        = '';
            foldersize.value        = '';
            remarks.value           = '';
        }

        const callClearFlightLineKML = () => {
            clearFlightLineKML();
            fileEl.value.value = '';
        }

        return { 
            ...computedrefs, ...variablerefs, 
            checkNUploadFlight, startUploading, 
            closeFlightSummaryBox,
            editAttributes, zoomToFlight, callClearFlightLineKML,
            clearAllCells
        }
    },
})
