import KML from 'ol/format/KML';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import GeoJSON from 'ol/format/GeoJSON';

import store from '@/store';

import globalToast from '@/composables/common/globalToast';

const flightLineHandler = () => {
    const { showGlobalToast } = globalToast();

    const handleFeature = (features: any, filename: any) => {
        const map = store.getters.g_mapObj;

        let filteredFeatures = features.filter((feat: any) => {
            return feat.getGeometry()?.getType() == 'LineString';
        });

        let feature = filteredFeatures[0];
        // console.log(feature);

        let cond1 = features.length != 0;
        let cond2 = filteredFeatures.length != 0;
        let cond3 = feature != undefined && feature != null;

        if(cond1 && cond2 && cond3){
            let lyr = new VectorLayer({
                source: new VectorSource({
                    features: [feature]
                }),
                zIndex: 3
            });

            lyr.set('name', 'flightlineKML');

            map.addLayer(lyr);
            map.getView().fit(lyr.getSource().getExtent());
            map.getView().setZoom(map.getView().getZoom() - 3);

            let featureGeometry = feature.getGeometry();
        
            let newfeatGeom = new GeoJSON().writeGeometry(featureGeometry, {
                dataProjection: 'EPSG:4326',
                featureProjection: 'EPSG:3857'
            });
            
            // console.log(newfeatGeom);

            let flightLineKML = {
                valid: true,
                geom: newfeatGeom,
                attributes: {}
            };
            
            console.log(flightLineKML);
            
            store.dispatch('flu_flightlinekml', flightLineKML);
        } else {
            showGlobalToast('KML File is not valid');
        }
    }

    const loadkml = (file: any) => {
        let filename = file.name;
        // console.log(filename);

        let reader = new FileReader();
        reader.onload = function () {

            let kmlstring = reader.result!;
            
            let kmlFeatures = new KML({
                extractStyles: false
            }).readFeatures(kmlstring, {
                dataProjection: 'EPSG:4326',
                featureProjection: 'EPSG:3857'
            });
    
            handleFeature(kmlFeatures, filename);
        }
        reader.readAsText(file);
    }

    const loadFlightLine = (e: any) => {
        clearFlightLineKML();

        let file = e.target.files[0];
        let fileFullname = file.name;
        let lastDot = fileFullname.lastIndexOf('.');
        let extension = fileFullname.substring(lastDot + 1);
        // console.log(fileFullname, extension);

        if (extension != 'kml'){
            showGlobalToast('Invalid File.. Only KML Flight Lines are allowed...');
        } else {
            loadkml(file);
        }
    }

    const zoomToFlight = () => {
        const map = store.getters.g_mapObj;

        try {
            map.getLayers().forEach((lyr: any) => {
                if (lyr.get('name') == 'flightlineKML') {
                    map.getView().fit(lyr.getSource().getExtent());
                    map.getView().setZoom(map.getView().getZoom() - 3);
                }
            });
        } catch (e) {}
    }

    const clearFlightLineKML = () => {
        const map = store.getters.g_mapObj;

        try {
            map.getLayers().forEach(function (layer: any) {
                if (layer.get('name') != undefined && layer.get('name') === 'flightlineKML') {
                    map.removeLayer(layer);
                }
            });
        } catch (e) {}

        store.dispatch('flu_flightlinekml', {});
    }

    return { loadFlightLine, zoomToFlight, clearFlightLineKML }
}

export default flightLineHandler;