import store from '@/store';

import globalToast from '@/composables/common/globalToast';
import flightLineHandler from '@/composables/flights/flightLineHandler';

const flightLineUploader = () => {
    const { showGlobalToast } = globalToast();
    const { clearFlightLineKML } = flightLineHandler();

    const tryToUploadFlightLine = (attributes: any, isFailedFlight: any) => {
        const username = store.getters.g_userdetails.username;
            const password = store.getters.g_userdetails.password;

        let geom: any;

        if(isFailedFlight){
            geom = ''
        } else {
            geom = store.getters.flu_flightlinekml.geom;
        }
        // console.log(attributes, geom);

        let requestObj = {
            type: 'flights',
            request: 'uploadflightline',
            validateusername: username,
            validatepassword: password,
            gjstr: geom,
            attributes,
            isFailedFlight
        };

        console.log(requestObj);

        const g_wssDomain = store.getters.g_wssDomain;
        const g_portDir = store.getters.g_portDir;
        const PORT = g_portDir[requestObj['type']];

        const wssURL = `ws://${g_wssDomain}:${PORT}`;
        const ws = new WebSocket(wssURL);

        ws.addEventListener('error', () => {
            showGlobalToast('Error establishing connection to Server... May be Internet Problem...');
        });
    
        ws.addEventListener('message', (event) => {
            let responseObj = JSON.parse(Buffer.from(event.data, 'base64').toString());
            console.log(responseObj);

            if(responseObj.requestStatus == 'success'){
                showGlobalToast('Flight Line Uploaded Successfully');
                
                clearFlightLineKML();
                store.dispatch('flu_flightlinekml', {});
                store.dispatch('flu_showFlightSummaryBox', false);
            } else {
                showGlobalToast('Error Uploading Flight Line');
            }

            ws.close();
        });

        ws.addEventListener('open', (event) => {
            ws.send(btoa(JSON.stringify(requestObj)));
        });
    }

    return { tryToUploadFlightLine };
}

export default flightLineUploader;